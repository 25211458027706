import { PortalModule } from '@angular/cdk/portal';
import { NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {
    Environment,
    ENVIRONMENT,
    LicenseNotificationModule,
    RestServiceModule,
    API_BASE_URLS,
    ModuleName,
    PluralLocalization,
    AlertModule,
} from 'plmt-core-library';

import { apiBaseUrls } from 'manager/src/environments/api-base-urls';
import { AppComponent } from './app.component';
import { routes } from './routes';
import { CURRENT_MODULE_NAME_TOKEN } from '../shared/tokens/module-token';
import { environment } from 'src/environments/environment';
import { PopupPortalModule } from 'src/shared/components/ui-kit-components/popup-portal/popup-portal.module';
import { NgLocalization } from '@angular/common';
import { ErrorPageComponent } from '../shared/components/error-page/error-page.component';

const providers: Provider[] = [
    {
        provide: API_BASE_URLS,
        useValue: apiBaseUrls,
    },
    {
        provide: ENVIRONMENT,
        useValue: environment.production
            ? Environment.Production
            : Environment.Development,
    },
    {
        provide: CURRENT_MODULE_NAME_TOKEN,
        useValue: ModuleName.Manager,
    },
    [{ provide: NgLocalization, useClass: PluralLocalization }],
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        RestServiceModule,
        BrowserModule,
        RouterModule.forRoot(routes),
        PortalModule,
        LicenseNotificationModule,
        PopupPortalModule,
        AlertModule,
        ErrorPageComponent,
    ],
    providers,
    bootstrap: [AppComponent],
})
export class AppModule {}
