import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PopupModule } from '../popup/popup.module';
import { PopupPortalComponent } from './popup-portal.component';

export * from './popup-portal.component';
export * from './popup-portal.service';

@NgModule({
    imports: [CommonModule, PopupModule],
    exports: [PopupPortalComponent],
    declarations: [PopupPortalComponent],
})
export class PopupPortalModule {}
