<router-outlet></router-outlet>

<core-license-notification></core-license-notification>

<ng-template [cdkPortalOutlet]="portalService.component | async"></ng-template>

<core-popup-portal></core-popup-portal>

<app-error-page></app-error-page>

<core-alert></core-alert>
