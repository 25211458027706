import { Component, OnInit, TemplateRef } from '@angular/core';
import { SubscribableComponent } from 'ngx-subscribable';

import { PopupPortalInternalService } from './popup-portal.internal-service';
import { PortalPopupAgent } from './popup-portal.service';

@Component({
    selector: 'core-popup-portal',
    templateUrl: './popup-portal.component.html',
    styleUrls: ['./popup-portal.component.less'],
})
export class PopupPortalComponent
    extends SubscribableComponent
    implements OnInit
{
    templates = new Set<TemplateRef<{}>>();

    private agents = new Map<TemplateRef<{}>, PortalPopupAgent>();

    constructor(
        private popupPortalInternalService: PopupPortalInternalService,
    ) {
        super();
    }

    ngOnInit(): void {
        const {
            templates,
            agents,
            popupPortalInternalService: { append, remove },
        } = this;

        this.subscriptions = [
            append.subscribe(({ template, agent }) => {
                templates.add(template);

                if (agent) agents.set(template, agent);
            }),

            remove.subscribe((template) => this.hide(template, true)),
        ];
    }

    // TODO: Над данным методом нужно провести рефакторинг.
    // Этот компонент отвечает за состояния модальных окон 'core-dialog-', дропдаунов 'core-dropdown' и тултипов.
    // Соответственно данный компонент является самостоятельным и не должен никаким образом знать о других компонентах.
    // В данном методе используется костыль в виде строчки 'const context = document.querySelector('app-dataset-settings')', из-за которой появляется
    // привязка у этого комопнента к другим компонентам, что абсолютно не верно.
    // !!!ЧТО НУЖНО СДЕЛАТЬ!!!:
    // Необходимо переписать метод так, чтобы можно было избавится от метода getChildTemplates(), который был добавлен в рамках задачи PD-5646.
    // Один из способов представлен тут:
    // { const agent = this.agents.get(template);
    // const testArr = Array.from(this.agents.keys());
    // const parentTemplateIndex = testArr.indexOf(template);
    // if (
    //     agent?.popupName?.includes('core-dialog') &&
    //     parentTemplateIndex < this.agents.size
    // ) {
    //     const size = this.agents.size;
    //     for (let i = parentTemplateIndex; i < size; i++) {
    //         this.templates.delete(testArr[i]);
    //         this.agents.delete(testArr[i]);
    //     }
    //   }
    // }
    // По вышеописанной логике, parentTemplateIndex - это индекс темплейта который мы закрываем. Далее мы проверям остались ли еще открытые темплейты при закрытии текущего,
    // если да, то удаляем и закрываем все оставшиеся. Пока стало непонятно почему во всех случаях возвращается parentTemplateIndex = 0. Нужно разобраться почему работает именно так.
    // ПРИМЕР: для решения задчи PD-6127, необходимо чтобы parentTemplateIndex = 1.

    hide(template: TemplateRef<{}>, isHideByEmit = false): void {
        const childTemplates = this.getChildTemplates(template);

        childTemplates.forEach((childTemplate) => {
            const context = document.querySelector('app-dataset-settings');

            let agent;

            if (context) {
                agent = this.agents.get(childTemplate);
            } else {
                const parentElement = childTemplate.createEmbeddedView(this);

                const isCoreDialog = parentElement.rootNodes.some(
                    (item) => item.className === 'wrapper',
                );

                agent = this.agents.get(
                    isCoreDialog ? template : childTemplate,
                );

                this.templates.delete(isCoreDialog ? template : childTemplate);
                this.agents.delete(isCoreDialog ? template : childTemplate);
            }

            if (agent?.tryHide && !isHideByEmit) {
                agent?.tryHide.emit();
            } else {
                const globalTooltip =
                    document.querySelector('body > sl-tooltip');
                const attrs = globalTooltip?.attributes;

                const openCondition = attrs?.getNamedItem('open');

                if (openCondition) attrs?.removeNamedItem('open');

                if (context) {
                    this.templates.delete(childTemplate);
                    this.agents.delete(childTemplate);
                }

                agent?.hide?.emit();
            }
        });
    }

    onKey(template: TemplateRef<{}>, key: string): void {
        this.agents.get(template)?.key?.emit(key);
    }

    onIndex(template: TemplateRef<{}>, index: number): void {
        this.agents.get(template)?.index?.emit(index);
    }

    visibleOverlay(template: TemplateRef<{}>): boolean {
        return this.agents.get(template)?.visibleOverlay ?? true;
    }

    overlayColor(template: TemplateRef<{}>): string | undefined {
        return this.agents.get(template)?.overlayColor;
    }

    popupName(template: TemplateRef<{}>): string | undefined {
        return this.agents.get(template)?.popupName;
    }

    // TODO: После правки метода hide() этот метод больше не понадобится и его можно будет удалить.
    // Сейчас он получает индекс темплейта который мы закрываем и если такой элемент открыт, то мы формируем массив из всех открытых элементов и переворачиваем его.

    private getChildTemplates(
        parentTemplate: TemplateRef<{}>,
    ): Set<TemplateRef<{}>> {
        const parentTemplateIndex = Array.from(this.templates).indexOf(
            parentTemplate,
        );

        if (parentTemplateIndex === -1) {
            return new Set<TemplateRef<{}>>();
        }

        // отделяем дочерние попапы и возвращаем в обратном порядке от последнего открытого попапа к первому
        const arrayOfChildTemplates = Array.from(this.templates)
            .slice(parentTemplateIndex, this.templates.size)
            .reverse();

        return new Set<TemplateRef<{}>>(arrayOfChildTemplates);
    }
}
