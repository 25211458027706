import {
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ChangeDetectionService } from 'ng-onpush';
import {
    DocumentTitleService,
    StorageKey,
    StorageService,
    ThemesEditService,
} from 'plmt-core-library';

import { PortalService } from 'dashboard/src/app/services/portal.service';

import { ChangeDetectionStrategy } from '@angular/core';

import { loadMessages, locale } from 'devextreme/localization';
import * as ruMessages from 'devextreme/localization/messages/ru.json';
import { AUTOTEST_HIDE_PROMT_MODALS } from '../shared/constants/autotests';
import { DashboardGlobalService } from './services/dashboard-global.service';
import { HotkeyService } from './services/hotkey.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    @HostListener('document:keydown', ['$event'])
    keyDown(event: KeyboardEvent) {
        this.hotkey.trigger(event);
    }

    @HostListener('document:keyup', ['$event'])
    keyUp(event: KeyboardEvent) {
        this.hotkey.trigger(event);
    }

    @HostListener('window:storage', ['$event'])
    changeStorage(event: StorageEvent) {
        if (event.key === StorageKey.AuthToken) {
            this.storageService.authToken.next(event.newValue || '');
        }
    }

    private unloadListener = (e: BeforeUnloadEvent): any => {
        const isHidden =
            localStorage.getItem(AUTOTEST_HIDE_PROMT_MODALS) !== null;

        if (isHidden) {
            return;
        }

        if (
            this.dashboardGlobalService.projectEditing ||
            this.themeEditService.showModal$.value
        ) {
            e.returnValue = false;
            return false;
        }
    };

    constructor(
        public portalService: PortalService,
        private changeDetectorRef: ChangeDetectorRef,
        private changeDetectionService: ChangeDetectionService,
        private documentTitleService: DocumentTitleService,
        private hotkey: HotkeyService,
        private dashboardGlobalService: DashboardGlobalService,
        private storageService: StorageService,
        private themeEditService: ThemesEditService,
    ) {}

    ngOnInit(): void {
        window.addEventListener('beforeunload', this.unloadListener);
        this.changeDetectionService.provideRootChangeDetectorRef(
            this.changeDetectorRef,
        );

        this.documentTitleService.update();

        loadMessages(ruMessages);
        locale('ru');
    }

    ngOnDestroy(): void {
        window.removeEventListener('beforeunload', this.unloadListener);
    }
}
