import { Routes } from '@angular/router';
import { dashboardRoutePath } from 'plmt-core-library';

export const routes: Routes = [
    {
        path: dashboardRoutePath + '/',
        redirectTo: dashboardRoutePath,
        pathMatch: 'full',
    },
    {
        path: dashboardRoutePath,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('dashboard/src/app/dashboard-app.module').then(
                        (m) => m.DashboardAppModule,
                    ),
            },
        ],
    },
    {
        path: '',
        loadChildren: () =>
            import('manager/src/app/manager-app.module').then(
                (m) => m.ManagerAppModule,
            ),
    },
];
