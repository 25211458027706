import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerIconLibrary, setBasePath } from '@shoelace-style/shoelace';

setBasePath('assets/shoelace');

if (environment.production) {
    enableProdMode();
}

registerIconLibrary('platform', {
    resolver: (name) => `/assets/icons/${name}.svg`,
});

registerIconLibrary('dashboard', {
    resolver: (name) => `assets/icons/${name}.svg`,
});

registerIconLibrary('core', {
    resolver: (name) => `/assets/core-library/icons/${name}.svg`,
});

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
