<core-popup
    *ngFor="let template of templates"
    [open]="true"
    [visibleOverlay]="visibleOverlay(template)"
    [overlayColor]="overlayColor(template)"
    [attr.popup-name]="popupName(template)"
    (openChange)="hide(template)"
    (key)="onKey(template, $event)"
    (index)="onIndex(template, $event)"
>
    <ng-template [ngTemplateOutlet]="template"></ng-template>
</core-popup>
